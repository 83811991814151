<template>
  <div>
    <b-card>
      <template slot="header">
        <h4 class="mt-2 custom-card-header-text">Responses</h4>
        <ul class="list-inline float-right custom-header-rightstyle  header_table">
            <!-- <li class="list-inline-item">
                <button type="button" v-b-tooltip.hover.bottom title="Accepted" @click="openAction('accepted')" class="btn custom-card-header-filter btn-dropbox"><i class="cil-short-text"></i></button>
            </li>
            <li class="list-inline-item">
                <button type="button" v-b-tooltip.hover.bottom title="Decline" @click="openAction('declined')" class="btn custom-card-header-filter btn-dropbox"><i class="cil-short-text"></i></button>
            </li>
            <li class="list-inline-item">
                <button type="button" v-b-tooltip.hover.bottom title="Referred" @click="openAction('referred')" class="btn custom-card-header-filter btn-dropbox"><i class="cil-short-text"></i></button>
            </li>
            <li class="list-inline-item">
                <button type="button" v-b-tooltip.hover.bottom title="Pending" @click="openAction('pending')" class="btn custom-card-header-filter btn-dropbox"><i class="cil-short-text"></i></button>
            </li>
            <li class="list-inline-item">
                <button type="button" v-b-tooltip.hover.bottom title="In Review" @click="openAction('inreview')" class="btn custom-card-header-filter btn-dropbox"><i class="cil-short-text"></i></button>
            </li> -->
            <li v-if="communities.length>1" class="list-inline-item place-center"><label for="communityFilter" class="custom-card-header-filter label-bottom-0">Community:</label></li>
            <li v-if="communities.length>1" class="list-inline-item">
              <b-form-select v-model="selected" @change="filterByCommunity()" class="custom-card-header-filter" id="communityFilter">
                <option :value="null" selected disabled>Community</option>
                <option value="all">All</option>
                <option v-for="community in communities" :key="community._id" v-bind:value="community._id">{{ community.name}}</option>
              </b-form-select>
            </li>
            <li v-if="circles.length>=1" class="list-inline-item  place-center"><label for="circleFilter" class="custom-card-header-filter  label-bottom-0">Circle:</label></li>
            <li v-if="circles.length>=1" class="list-inline-item">
              <b-form-select v-model="selectedCircle" @change="filterByCircle()" class="custom-card-header-filter" id="circleFilter">
                <option :value="null" selected disabled>Circle</option>
                <option value="all">All</option>
							  <option value="none">No Circle</option>
                <option v-for="(circle, idx) in circles" :key="`${idx}$-{circle.short}`" v-bind:value="circle.short" v-if="circle.name && circles.length>=1" >{{ circle.name}}</option>
              </b-form-select>
            </li>
            <li>
              <div class="row-bak d-flex-bak align-items-center-bak">
                <datepicker @changed="dateFilter" 
                style="padding-right: 10px;" label="Date Range"></datepicker>
              </div>
            </li>
            <li class="list-inline-item place-center"><label for="actionFilter" class="custom-card-header-filter  label-bottom-0">Response Type:</label></li>
            <li class="list-inline-item" v-if="!$route.query.action">
                <b-form-select v-model="selectedAction" v-on:change="filterByAction()" class="custom-card-header-filter" id="actionFilter">
                    <option value="All" selected>All Responses</option>
                    <option value="accepted">Accepted</option>
                    <option value="declined">Declined</option>
                    <option value="referred">Referred</option>
                    <option value="pending">Pending</option>
                    <option value="inreview">In Review</option>
                    <option value="snoozed">Snoozed</option>
                </b-form-select>
            </li>
            <li class="list-inline-item">
                <button type="button" aria-label="Export" aria-live="assertive" v-if="exportBtn" @click="exportData()" title="Download response data" class="btn custom-card-header-filter btn-dropbox tab-focus"><i class="icon-cloud-download"></i></button>
                <button type="button" v-if="processBtn" v-b-tooltip.hover.bottom title="Generating report" class="btn custom-card-header-filter btn-dropbox"><i class="fa fa-spinner fa-spin"></i></button>
            </li>
        </ul>
      </template>
      <b-card-body>
        <v-server-table :url="serverURL" :columns="columns" :options="options" name="dataTable" id="dataTable" @loaded="onLoaded" class="responses-table">
            <span slot="community" slot-scope="props">
              {{props.row.community ? props.row.community : 'N/A'}}
            </span>
            <span slot="title" slot-scope="props">
              {{props.row.title ? props.row.title : 'N/A'}}
            </span>
            <span slot="adviser" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if="props.row.memberId">
              {{props.row.adviser}}
            </span>
            <span slot="adviser" slot-scope="props" v-else-if="props.row.adviser">
              {{props.row.adviser}}
            </span>
            <span slot="sent" slot-scope="props" v-if="props.row.sent">
              {{ props.row.sent | momentDate }}
            </span>
            <span slot="sent" v-else>N/A</span>
            <span slot="status" v-if="props.row.isReferralGive == 'referred'" slot-scope="props" class="badge badge-warning">
            Referred
            </span>
            <span v-else slot="status" slot-scope="props" :class="[{'badge': props.row.status}, {'badge-success': setStatus(props.row.status) == 'Accepted'}, {'badge-danger': setStatus(props.row.status) == 'Declined'}, {'badge-blue': setStatus(props.row.status) == 'Snoozed'}, {'badge-warning': setStatus(props.row.status) == 'In Review' || setStatus(props.row.status, props.row) == 'Pending'}]">
              {{props.row.status ? setStatus(props.row.status) : 'N/A'}}
            </span>
            <span slot="followUp" slot-scope="props" class="capitalize" v-if="props.row.followUp">
              {{ props.row.followUp }}
            </span>
            <span slot="action" slot-scope="props" class="center-dropdown">
              <!-- <b-button v-b-tooltip.hover.bottom aria-label="View the request" variant="dropbox tab-focus" size="sm" class="notification-btn" @click="openRequestInNewTab(props.row.requestId)">
                  <span class="count" v-if="props.row.commentCount">{{props.row.commentCount}}</span>
                  <i class="icon-eye"></i>
              </b-button> -->
              <b-dropdown v-b-tooltip.hover.bottom title="Take action" variant="btn btn-dropbox btn-sm tab-focus" right>
              <template slot="button-content">
                <i class="icon-settings"></i>
              </template>
              <b-dropdown-item @click="openRequestInNewTab(props.row.requestId)">View Request</b-dropdown-item>
              <b-dropdown-item @click="approveResponse(props.row.requestId, props.row.giveId, 'approve', 'no',props.row.giveType)">Approve Response</b-dropdown-item>
              <b-dropdown-item @click="approveResponse(props.row.requestId, props.row.giveId, 'approve', 'yes',props.row.giveType)">Approve Member</b-dropdown-item>
              <b-dropdown-item @click="$router.push(`/engagement/requests-response-coach/${props.row.requestId}/${props.row.giveId}/?location=responses`)">Reject and coach</b-dropdown-item>
              <b-dropdown-item @click="approveResponse(props.row.requestId, props.row.giveId, 'reject', 'no',props.row.giveType)">Reject and delete</b-dropdown-item>
            </b-dropdown>
            </span>
        </v-server-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { shuffleArray } from '@/shared/utils';
import axios from 'axios';
import { exportCSV } from '@/assets/js/common';
var moment = require('moment-timezone');
import VueNotifications from 'vue-notifications'
import { setResponseStatus } from '@/assets/js/set-status';
import datepicker from './components/datePicker';

export default {
  name: 'responses',
  components: {
		datepicker,
	},
  data() {
    return {
      responses: [],
      communities: [],
      circles: [],
      searchQuery: '',
      askCount: 0,
      selected: "all",
      selectedCircle: "all",
      selectedAction: "All",
      selectedDates: {
				startDate: new Date(0),
				endDate: new Date()
			},
      actions: ['accepted', 'declined', 'referred', 'pending', 'in review'],
      columns: ['community', 'circleName', 'sent', 'adviser', 'request_Action', 'status', 'open', 'click', 'thankYou', 'followUp', 'action'],
      token: '',
      perPage: 25,
      options: {
        headings: {
          requestId: 'Request ID',
          open: function (h) {return <span data-title="Askers that opened the response">O</span>},
          click: function (h) {return <span data-title="Askers that clicked within the response email">C</span>},
          thankYou: function (h) {return <span data-title="Askers that thanked the Advisor">TY</span>},
          followUp: function (h) {return <span data-title="Askers that followed up">FU</span>},
          circleName: 'Circle'
        },
        sortable: ['circleName', 'sent', 'adviser', 'request_Action', 'status'],
        // sortable: ['sent', 'adviser', 'request_Action', 'status', 'open', 'click'],
        sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        pagination: {
            chunk: 10,
            nav: 'fixed'
        },
        requestFunction: async function (data) {
            this.$parent.searchQuery = data.query;
            if (this.$route.query.action) {
                data.queryAction = this.$route.query.action;
            }
            return await axios.get(this.url, {headers: {'x-access-token': localStorage.getItem('token')},  params: data}).catch(function (e) {
                if (e.response.status  === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            }.bind(this));
        },
        responseAdapter({data}) {
            this.$parent.askCount = data.askCount;
            return {
                data : data.responses,
                count: data.askCount
            }
        },
        customSorting: {
          sent: function (ascending) {
              return function (a, b) {
                  var isValid = function(date) {
                    return !isNaN(isFinite(date))
                  }
                  var lastA = a.sent;
                  var lastB = b.sent;
                  if (ascending && isValid(lastA) && isValid(lastB)) {
                    return new Date(lastA) - new Date(lastB);
                  }
                  if (!ascending && isValid(lastA) && isValid(lastB)) {
                    return lastA <= lastB ? 1 : -1;
                  }
              }
          }
        }
      },
      serverURL: `${process.env.VUE_APP_API_BASE_URL}/dashboard/response`,
      userData: {},
      exportBtn: true,
      processBtn: false,
      downloadBtn: false,
    }
  },
  notifications: {
      showDownloadingMsg: {
        type: VueNotifications.types.success,
        message: "Export request sent.  Data is now downloading."
      },
      showWarnMsg: {
          type: VueNotifications.types.warn,
          message: "No data found for export file"
      }
  },
  async created() {
    if (this.$route.query.status) {
      console.log("this.$route.query.status", this.$route.query.status.trim());
    if (this.$route.query.status.trim() === 'approved') {
      console.log("this is running");
      this.showSuccessMsg({ message: 'The give is already approved.' });
    } else if (this.$route.query.status.trim() === 'rejected') {
      this.showErrorMsg({ message: 'The give is already rejected.' });
    }
    const newQuery = { ...this.$route.query };
      delete newQuery.status;
      this.$router.replace({ query: newQuery });
    }
    if (this.$route.query.action) {
        this.selectedAction = this.$route.query.action;
    }
    this.token = localStorage.getItem('token');
    const user = localStorage.getItem('authUser');
    if(user) {
        this.authUser = JSON.parse(user);
    }
    await this.getCommunities();
    await this.loadData();
  },
  methods: {
    dateFilter(res) {
			// console.log("dates [selectedDates]:", res);
			this.selectedDates.startDate =  res.startDate;
			this.selectedDates.endDate =  res.endDate;
			this.onLoaded();
		},
    onLoaded() {
        if (this.selected) {
            this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/response/?action=${this.selectedAction}&community=${this.selected}&circle=${this.selectedCircle}&startDate=${this.selectedDates.startDate}&endDate=${this.selectedDates.endDate}`;
        }
    },
    loadData() {
        var user = localStorage.getItem('authUser');
        this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/users/${JSON.parse(user)._id}/edit-profile`, {headers: {'x-access-token': this.token}}).then(response => {
            if (response.data.user) {
                this.userData = response.data.user;
                this.exportBtn = true;
                this.processBtn = false;
                this.downloadBtn = false;
                if (response.data.user.responseCSVFileName) {
                    this.exportBtn = false;
                    this.processBtn = false;
                    this.downloadBtn = true;
                } else if (response.data.user.responseExportCSV) {
                    this.exportBtn = false;
                    this.processBtn = true;
                    this.downloadBtn = false;
                }
            }
        }).catch(err => {
            if (err.response.status === 401) {
                localStorage.removeItem('authUser');
                this.$router.push('/login');
            }
        });
        var queryParams = {
            headers: {'x-access-token': this.token}
        }
        if (this.selected && this.selected != 'all') {
            queryParams.params = {community: this.selected};
        }
        this.$helpers.setTabindexOnTableCells();
    },
    checkData(value) {
      if(value) { return value; } else { return 'N/A';}
    },
    showSuccessMsg({ message }) {
        VueNotifications.success({ message });
    },
    showErrorMsg({ message }) {
        VueNotifications.error({ message });
    },
    announcement(message) {
        const liveRegion = document.createElement('div');
        liveRegion.setAttribute('aria-live', 'polite');
        liveRegion.setAttribute('role', 'alert');
        liveRegion.innerText = message;
        document.body.appendChild(liveRegion);
        setTimeout(() => {
            liveRegion.remove();
        }, 1000);
    },
    approveResponse: function(askId, giveId, action, status,giveType) {
      console.log("giveType", giveType);
      console.log("askId", askId);
      console.log("giveId", giveId);
      console.log("action", action);
      console.log("status", status);
      if(giveType==='in review' || giveType==='pending'){
        let params = {
            askId: askId,
            giveId: giveId,
            action: action,
            status: status,
            approvedBy: 'dashboard'
        }
        console.log("authUser", this.authUser);
        if (this.authUser.memberID) {
            params.adminId = this.authUser.memberID;
        }
        this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/response-approval`, params, {headers: {'x-access-token': this.token}}).then(async response => {
            // setTimeout(async () => {
              await this.loadData();
              let message = '';
              if (action == 'approve') {
                message = 'Thank you for approving the response.'
                this.showSuccessMsg({message: message});
                this.announcement(message);
              } else if (action == 'reject') {
                message = 'Thank you for your response';
                this.showSuccessMsg({message: message})
                this.announcement(message);
              }
            // }, 2000);
        }).catch(err => {
            if (err.response.status  === 401) {
                localStorage.removeItem('authUser');
                this.$router.push('/login');
            }
        });
      }
      else{
        let message = '';
      if (giveType === 'accepted') {
        message = 'The response has already been accepted.';
      } else if (giveType === 'declined') {
        message = 'The response has already been declined.';
      } else if (giveType === 'referred') {
        message = 'The response has already been referred.';
      }
      this.showErrorMsg({ message: message });
      this.announcement(message);
      }
    },
    async getCommunities() {
      const {data} = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/sidebar-community`, {headers: {'x-access-token': this.token}})
      .catch(err => {
        if (err.response.status  === 401) {
          localStorage.removeItem('authUser');
          this.$router.push('/login');
        }
      });
      this.communities = data;
      if(this.$route.params.community_id) {
        var selectedCommunity = data.filter(community => community._id == this.$route.params.community_id);
        if (selectedCommunity.length <= 1) {
					this.selected = selectedCommunity[0]._id;
				}
			} else {
				if (this.communities.length <= 1) {
					this.selected = this.communities[0]._id;
				}
			}
			this.filterByCommunity();
    },
    filterByCommunity: async function(value) {
      this.selectedCircle = "all";
      if(this.selected =="all"){
        this.circles = [];
        this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/response`;
      } else {
        this.circles = this.communities.find(c => c._id == this.selected).additionalParams;
        this.circles.sort(function (a, b) {
					return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
				});
        this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/response/?action=${this.selectedAction}&community=${this.selected}`;
      }
    },
		filterByCircle: async function () {
			if (this.selectedCircle == "all") {
				this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/response/?action=${this.selectedAction}&community=${this.selected}`;
			} else {
				this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/response/?action=${this.selectedAction}&community=${this.selected}&circle=${this.selectedCircle}`;
			}
		},
    filterByAction: async function() {
      if (this.selectedAction == "all") {
				this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/response/?community=${this.selected}&circle=${this.selectedCircle}`;
			} else {
				this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/response/?action=${this.selectedAction}&community=${this.selected}&circle=${this.selectedCircle}`;
			}
    },
    setStatus: function(status) {
      return setResponseStatus(status);
    },
    exportData: function() {
      if (this.askCount > 0) {
        this.showDownloadingMsg();
				this.exportBtn = false;
				this.processBtn = true;
        var queryParams = {
            headers: {'x-access-token': this.token}
        }
        this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/response/export/?action=${this.selectedAction}&community=${this.selected}&circle=${this.selectedCircle}&query=${this.searchQuery}&&startDate=${this.selectedDates.startDate}&&endDate=${this.selectedDates.endDate}`, queryParams).then(async response => {
          if (response.data.responses.length) {
              let responseData = this.$lodash.orderBy(response.data.responses, ['sent'], ['desc']);
              await exportCSV(responseData.slice(),  'responses', ['title','memberId', 'isReferralGive', 'commentCount'], ['sent']);
              this.exportBtn = true;
						  this.processBtn = false;
          } else {
            this.showWarnMsg();
            this.exportBtn = true;
            this.processBtn = false;
          }
        }).catch(err => {
            if (err.response.status  === 401) {
                localStorage.removeItem('authUser');
                this.$router.push('/login');
            }
        });
      } else {
        this.showWarnMsg();
      }
    },
    downloadCSV: function() {
        if (this.userData && this.userData.responseCSVFileName) {
            var file = this.userData.responseCSVFileName;
            axios({
                method: 'get',
                url: `${process.env.VUE_APP_API_BASE_URL}/member/${this.userData._id}/${this.userData.responseCSVFileName}`,
                responseType: 'arraybuffer'
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', file) //or any other extension
                document.body.appendChild(link)
                link.click()
                this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/response/responseRemoveCSV`, { headers: {'x-access-token': this.token} }).then(response => {
                    this.exportBtn = true;
                    this.processBtn = false;
                    this.downloadBtn = false;
                }).catch(err => {
                    if (err.response.status  === 401) {
                        localStorage.removeItem('authUser');
                        this.$router.push('/login');
                    }
                });
            }).catch(() => console.log('error occured'))
        }
    },
    /*
    openAction: function(action) {
        window.open((`/engagement/responses/?action=${action}`), '_blank');
    },
    */
    redirectUrls: function(path) {
        window.open((`/engagement/members/${path}`), '_blank');
    },
    openRequestInNewTab(path) {
      window.open((`/engagement/requests/${path}`), '_blank');
    }
  }
}
</script>
