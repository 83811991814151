<template>
    <div>
        <form @change.prevent="saveDetail('form-profile')" data-vv-scope="form-profile" class="textlable-spaceright" v-if="!loading">
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Community</div>
                <div style="max-width: 600px;">
                    <b-form-select class="mb-3 custom-select" @change="changeCommunity()" :disabled="member.isDeleted.status == true" v-model.trim="newComm" name="community" placeholder="Community" id="community" size="sm">
                        <option v-for="(singleCommunity) in allCommunities" v-bind:key="singleCommunity._id" :value="singleCommunity._id">{{ singleCommunity.name }}</option>
                    </b-form-select>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Circles</div>
                <div style="max-width: 600px;">
                    <b-form-textarea v-model.trim="circleList" name="circleList" placeholder="Enter circles..." type="text" size="sm"></b-form-textarea>
                    <p class="text-muted">Multiple skills must be comma(,) separated</p>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Groups</div>
                <div style="max-width: 600px;">
                    <b-form-textarea v-model.trim="groups" v-validate.continues="'max:500'" :maxlength="500" placeholder="Enter Groups..."  type="text" size="sm"></b-form-textarea>
                    <p class="text-muted float-right">  {{ 500 - remainingGroupCharacters }}/500</p><p class="text-muted">Multiple group must be comma(,) separated</p>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Member Type</div>
                <div style="max-width: 600px;">
                    <b-form-select class="mb-3 custom-select" :disabled="member.isDeleted.status == true" @change="saveDetail2('memberType', memberType)" v-model="memberType" size="sm" name="memberType" placeholder="Member Type" id="memberType">
                        <option value="Member">Member</option>
                        <option value="Advisor">Advisor</option>
                    </b-form-select>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Member Flag</div>
                <div style="max-width: 600px;">
                    <b-form-select v-model="memberFlag" :disabled="member.isDeleted.status == true" @change="saveDetail2('memberFlag', memberFlag)" name="memberFlag" size="sm" placeholder="Member Flag" id="memberFlag">
                        <option value="Flagged">Flag Member</option>
                        <option value="Unflagged">Unflag Member</option>
                    </b-form-select>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Member Role</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.member_role" :disabled="member.isDeleted.status == true" placeholder="Enter member Role..."  type="text" size="sm" name="member_role"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Member Rank</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.member_rank" :disabled="member.isDeleted.status == true" placeholder="Enter member Rank..."  type="text" size="sm" name="member_rank"></b-form-input>
                    <p class="text-muted">Multiple group must be comma(,) separated</p>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Advice Area</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.advice_area" :disabled="member.isDeleted.status == true" placeholder="Enter advice area..."  type="text" size="sm" name="advice_area"></b-form-input>
                    <p class="text-muted">Multiple group must be comma(,) separated</p>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Affinity</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.affinity" :disabled="member.isDeleted.status == true" placeholder="Enter affinity..."  type="text" size="sm" name="affinity"></b-form-input>
                    <p class="text-muted">Multiple group must be comma(,) separated</p>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Property ID</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.propertyId" :disabled="member.isDeleted.status == true" placeholder="Enter property Id..."  type="text" size="sm" name="propertyId"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Student ID</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.studentId" :disabled="member.isDeleted.status == true" placeholder="Enter student Id..."  type="text" size="sm" name="studentId"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">First Name</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.first" :disabled="member.isDeleted.status == true" v-validate="'required'" placeholder="Enter first name..."  type="text" size="sm" name="first"></b-form-input>
                    <span class="md-helper-text error" v-show="errors.has('form-profile.first')">{{errors.first('form-profile.first')}}</span>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Last Name</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.last" :disabled="member.isDeleted.status == true"  v-validate="'required'" placeholder="Enter last name..."  type="text" size="sm" name="last"></b-form-input>
                    <span class="md-helper-text error" v-show="errors.has('form-profile.last')">{{errors.first('form-profile.last')}}</span>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Email Address</div>
                <div style="display: flex;">
                    <div style="max-width: 490px; width: 500px; margin: 0px 20px 0px 0px;">
                        <b-form-input v-model.trim="member.emails" placeholder="Email Address"  type="text" size="sm" name="email"  v-validate="'required|email'" :disabled="isEmailLinkDisable == false"></b-form-input>
                        <span class="md-helper-text error" v-show="errors.has('form-profile.email')">{{errors.first('form-profile.email')}}</span>
                    </div>
                    <div v-if="!isEmailDisable && member.isDeleted.status == false && !member.unsubscribe">
                        <b-button type="button" size="sm" variant="danger" v-on:click="isEmailLinkDisable = true" class="md-button md-red md-round md-accent md-theme-default">Update email</b-button>
                    </div>
                </div>

            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Email Address 2</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.email_address2" placeholder="Email Address 2"  type="text" size="sm" name="email_address2" disabled></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Email Address 3</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.email_address3" placeholder="Email Address 3"  type="text" size="sm" name="email_address3" disabled></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Phone</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.phoneNumbers" :disabled="member.isDeleted.status == true" placeholder="Enter phone numbers..."  type="text" size="sm" name="phoneNumbers"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Role</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.roles" :disabled="member.isDeleted.status == true" placeholder="Enter role..."  type="text" size="sm" name="roles"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Role Description</div>
                <div style="max-width: 600px;">
                    <b-form-textarea v-model.trim="member.roleDescription" :disabled="member.isDeleted.status == true" v-validate.continues="'max:2000'" :maxlength="2000" placeholder="Enter role description..."  type="text" size="sm" name="roleDescription"></b-form-textarea>
                    <p class="text-muted float-right">  {{ 2000 - remainingRoleCharacters }}/2000</p>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Organization</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.organization" :disabled="member.isDeleted.status == true" placeholder="Enter organization..."  type="text" size="sm" name="organization"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Organization Description</div>
                <div style="max-width: 600px;">
                    <b-form-textarea v-model.trim="member.organizationDescription" :disabled="member.isDeleted.status == true" v-validate.continues="'max:2000'" :maxlength="2000" placeholder="Enter organization description..."  type="text" size="sm" name="organizationDescription"></b-form-textarea>
                    <p class="text-muted float-right">  {{ 2000 - remainingOrganizationCharacters }}/2000</p>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">City</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.city" :disabled="member.isDeleted.status == true" placeholder="Enter city..."  type="text" size="sm" name="city"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">State</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.state" :disabled="member.isDeleted.status == true" placeholder="Enter state..."  type="text" size="sm" name="state"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Postal Code</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.postal_code" :disabled="member.isDeleted.status == true" placeholder="Enter postal code..."  type="text" size="sm" name="postal_code"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Country</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.country" :disabled="member.isDeleted.status == true" placeholder="Enter country..."  type="text" size="sm" name="country"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Citizenship 1</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.citizenship1" :disabled="member.isDeleted.status == true" placeholder="Enter citizenship 1..."  type="text" size="sm" name="citizenship1"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Citizenship 2</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.citizenship2" :disabled="member.isDeleted.status == true" placeholder="Enter citizenship 2..."  type="text" size="sm" name="citizenship2"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Portal URL</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.portalURL" :disabled="member.isDeleted.status == true" placeholder="Enter portal URL..."  type="text" size="sm" name="portalURL"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Industry</div>
                <div style="max-width: 600px;">
                    <b-form-textarea v-model.trim="industryList" :disabled="member.isDeleted.status == true" placeholder="Enter industry..."  type="text" size="sm" name="industry"></b-form-textarea>
                    <p class="text-muted">Multiple group must be comma(,) separated</p>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Bio</div>
                <div style="max-width: 600px;">
                    <b-form-textarea v-model.trim="newBio" :disabled="member.isDeleted.status == true" placeholder="Enter bio..."  type="text" size="sm" name="bio"></b-form-textarea>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Bio Keyword</div>
                <div style="max-width: 600px;">
                    <b-form-textarea v-model.trim="newBioKeyword" :disabled="member.isDeleted.status == true" placeholder="Enter bio keyword..."  type="text" size="sm" name="newBioKeyword"></b-form-textarea>
                    <p class="text-muted">Multiple group must be comma(,) separated</p>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">LinkedIn URL</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.linkedIn" :disabled="member.isDeleted.status == true" placeholder="Enter linkedIn URL..."  type="text" size="sm" name="linkedIn" v-validate="{ required: false, regex: /^http(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-\.\?\,\'\/\\\+&amp;%\$#_]*)?$/ }"></b-form-input>
                    <span class="md-helper-text error" v-show="errors.has('form-profile.linkedIn')">{{errors.first('form-profile.linkedIn')}}</span>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Skills & Expertise</div>
                <div style="max-width: 600px;">
                    <b-form-textarea v-model.trim="skillsList" :disabled="member.isDeleted.status == true" placeholder="Enter skills..."  type="text" size="sm" name="skills"></b-form-textarea>
                    <p class="text-muted">Multiple group must be comma(,) separated</p>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Interests</div>
                <div style="max-width: 600px;">
                    <b-form-textarea v-model.trim="interestList" :disabled="member.isDeleted.status == true" placeholder="Enter interests..."  type="text" size="sm" name="interests"></b-form-textarea>
                    <p class="text-muted">Multiple group must be comma(,) separated</p>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Marketing Opt-In?</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.country" :disabled="member.isDeleted.status == true" name="marketingOptIn" placeholder="Enter marketing Opt-In"  type="text" size="sm"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Race</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.race" :disabled="member.isDeleted.status == true" placeholder="Enter race..."  type="text" size="sm" name="race"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Gender</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.gender" :disabled="member.isDeleted.status == true" placeholder="Enter gender..."  type="text" size="sm" name="gender"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Age</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.age" :disabled="member.isDeleted.status == true" placeholder="Enter age..."  type="text" size="sm" name="age"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Online Calendar URL</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.calendar" :disabled="member.isDeleted.status == true" placeholder="Enter calendar URL..."  type="text" size="sm" name="calendar"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Twitter URL</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.twitter" :disabled="member.isDeleted.status == true" placeholder="Enter twitter..."  type="text" size="sm" name="twitter"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Instagram URL</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.instagram" :disabled="member.isDeleted.status == true" placeholder="Enter instagram..."  type="text" size="sm" name="instagram"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Skype ID</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.skypeID" :disabled="member.isDeleted.status == true" placeholder="Enter skypeID..."  type="text" size="sm" name="skypeID"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">School 1</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.school1" :disabled="member.isDeleted.status == true" placeholder="Enter school 1..."  type="text" size="sm" name="school1"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Department 1</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.department1" :disabled="member.isDeleted.status == true" placeholder="Enter department 1..."  type="text" size="sm" name="department1"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Major 1</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.major1" :disabled="member.isDeleted.status == true" placeholder="Enter major 1..."  type="text" size="sm" name="major1"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Degree 1</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.degree1" :disabled="member.isDeleted.status == true" placeholder="Enter degree 1..."  type="text" size="sm" name="degree1"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Year 1</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.year1" :disabled="member.isDeleted.status == true" placeholder="Enter year 1..."  type="text" size="sm" name="year1"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">School 2</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.school2" :disabled="member.isDeleted.status == true" placeholder="Enter school 2..."  type="text" size="sm" name="school2"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Department 2</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.department2" :disabled="member.isDeleted.status == true" placeholder="Enter department 2..."  type="text" size="sm" name="department2"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Major 2</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.major2" :disabled="member.isDeleted.status == true" placeholder="Enter major 2..."  type="text" size="sm" name="major2"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Degree 2</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.degree2" :disabled="member.isDeleted.status == true" placeholder="Enter degree 2..."  type="text" size="sm" name="degree2"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Year 2</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.year2" :disabled="member.isDeleted.status == true" placeholder="Enter year 2..."  type="text" size="sm" name="year2"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">School 3</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.school3" :disabled="member.isDeleted.status == true" placeholder="Enter school 3..."  type="text" size="sm" name="school3"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Department 3</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.department3" :disabled="member.isDeleted.status == true" placeholder="Enter department 3..."  type="text" size="sm" name="department3"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Major 3</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.major3" :disabled="member.isDeleted.status == true" placeholder="Enter major 3..."  type="text" size="sm" name="major3"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Degree 3</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.degree3" :disabled="member.isDeleted.status == true" placeholder="Enter degree 3..."  type="text" size="sm" name="degree3"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Year 3</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.year3" :disabled="member.isDeleted.status == true" placeholder="Enter year 3..."  type="text" size="sm" name="year3"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">School 4</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.school4" :disabled="member.isDeleted.status == true" placeholder="Enter school 4..."  type="text" size="sm" name="school4"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Department 4</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.department4" :disabled="member.isDeleted.status == true" placeholder="Enter department 4..."  type="text" size="sm" name="department4"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Major 4</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.major4" :disabled="member.isDeleted.status == true" placeholder="Enter major 4..."  type="text" size="sm" name="major4"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Degree 4</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.degree4" :disabled="member.isDeleted.status == true" placeholder="Enter degree 4..."  type="text" size="sm" name="degree4"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Year 4</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.year4" :disabled="member.isDeleted.status == true" placeholder="Enter year 4..."  type="text" size="sm" name="year4"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">School 5</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.school5" :disabled="member.isDeleted.status == true" placeholder="Enter school 5..."  type="text" size="sm" name="school5"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Department 5</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.department5" :disabled="member.isDeleted.status == true" placeholder="Enter department 5..."  type="text" size="sm" name="department5"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Major 5</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.major5" :disabled="member.isDeleted.status == true" placeholder="Enter major 5..."  type="text" size="sm" name="major5"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Degree 5</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.degree5" :disabled="member.isDeleted.status == true" placeholder="Enter degree 5..."  type="text" size="sm" name="degree5"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Year 5</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.year5" :disabled="member.isDeleted.status == true" placeholder="Enter year 5..."  type="text" size="sm" name="year5"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Image URL</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.imageURL" :disabled="member.isDeleted.status == true" placeholder="Enter image URL..."  type="text" size="sm" name="imageURL"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Website URL</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.website" :disabled="member.isDeleted.status == true" placeholder="Enter website URL..."  type="text" size="sm" name="website"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Facebook URL</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.facebook" :disabled="member.isDeleted.status == true" placeholder="Enter facebook URL..."  type="text" size="sm" name="facebook"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Company Stage</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.companyStage" :disabled="member.isDeleted.status == true" placeholder="Enter company stage..."  type="text" size="sm" name="companyStage"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Annual Revenue</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.annualRevenue" :disabled="member.isDeleted.status == true" placeholder="Enter annual revenue..."  type="text" size="sm" name="annualRevenue"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Team Size</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.teamSize" :disabled="member.isDeleted.status == true" placeholder="Enter team size..."  type="text" size="sm" name="teamSize"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">WordPress Link</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.wordPressLink" type="text" size="sm" name="wordPressLink" disabled></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Custom Value 1</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.customer_custom1" :disabled="member.isDeleted.status == true" type="text" size="sm" name="customer_custom1"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Custom Value 2</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.customer_custom2" :disabled="member.isDeleted.status == true" type="text" size="sm" name="customer_custom2"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Custom Value 3</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.customer_custom3" :disabled="member.isDeleted.status == true" type="text" size="sm" name="customer_custom3"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Engagement Points</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.engagementPoints" type="text" size="sm" name="engagementPoints" disabled></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Approve for Requests?</div>
                <div style="max-width: 600px;">
                    <b-form-select v-model="requestStatus" :disabled="member.isDeleted.status == true" @change="saveDetail2('requestStatus', requestStatus)" name="requestStatus" size="sm" placeholder="Approve for Requests?" id="requestStatus">
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </b-form-select>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Approve for Responses?</div>
                <div style="max-width: 600px;">
                    <b-form-select v-model="responseStatus" :disabled="member.isDeleted.status == true" @change="saveDetail2('responseStatus', responseStatus)" name="responseStatus" size="sm" placeholder="Approve for Responses?" id="responseStatus">
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </b-form-select>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Terms Accepted</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.termsAccepted" type="text" size="sm" name="termsAccepted" disabled ></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Frequency</div>
                <div style="max-width: 600px;">
                    <b-form-select :disabled="member.isDeleted.status == true" @change="saveDetail2('frequency', frequency)" size="sm" v-model.trim="frequency" name="frequency" placeholder="Frequency" class="capitalize">
                        <option :value="frequency" :key="frequency" v-for="frequency in availableFrequency">{{toTitleCase(frequency)}}</option>
                    </b-form-select>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Engagement</div>
                <div style="max-width: 600px;">
                    <b-form-input :disabled="member.isDeleted.status == true" v-model.trim="member.engagement" type="text" size="sm" name="engagement" ></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Capacity</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.capacity" :disabled="member.isDeleted.status == true" type="text" size="sm" name="capacity" ></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Propensity</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model.trim="member.propensity" :disabled="member.isDeleted.status == true" type="text" size="sm" name="propensity" ></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Member Status</div>
                <div style="max-width: 600px;">
                    <b-form-input v-model="member_status" :disabled="true" name="member_status" size="sm" id="member_status"></b-form-input>
                </div>
            </div>
            <div class="member-detail-flex px-0">
                <div class="card-item md-subheading mt-30">Account Owner</div>
                <div style="max-width: 600px;">
                    <b-form-textarea v-model.trim="accountOwnerList" :disabled="member.isDeleted.status == true" placeholder="Account Owner..." type="text" size="sm" name="account_owner"></b-form-textarea>
                    <p class="text-muted">Multiple skills must be comma(,) separated</p>
                </div>
            </div>
            <div class="member-detail-flex px-0 p-0">
                <div></div>
                <div style="display: flex; justify-content: end; max-width: 600px;">
                    <b-button style="margin: 0px 10px 0px 0px;" type="button" variant="danger" size="sm" @click="unsubscribeMember()" v-if="(member.isDeleted.status == false || typeof member.isDeleted == 'undefined') && !member.unsubscribe" class="md-button md-red md-round md-accent md-theme-default">Unsubscribe</b-button>
                    <b-button type="button" variant="danger" size="sm" @click="deleteMember()" v-if="member.isDeleted.status == false || typeof member.isDeleted == 'undefined'" class="md-button md-red md-round md-accent md-theme-default">Delete Member</b-button>
                </div>
            </div>
        </form>
        <div v-else>
            <div class="shimmerEffect br">
                <div class="wrapper">
                    <div class="flex" v-for="(x, index) in 80">
                        <div class="comment br animate w20"></div>
                        <div class="comment br animate w60"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import './css/shimmerUI.css';

export default {
    name: 'UpdateMemberDetails',
    components: {
    },
    data() {
        return {
            member: {
                isDeleted: {}
            },
            community: {},
            memberActivity: '',
            token: localStorage.getItem('token'),
            circles: '',
            memberType: '',
            maxGroupCharacters: 500,
            maxRoleCharacters: 2000,
            maxOrganizationCharacters: 2000,
            editMemberDetailStatus: false,
            authUser: {},
            renderUnsubscribe: false,
            community: {},
            circleList: '',
            skillsList: '',
            fullPage: true,
            interestList: '',
            allCommunities: '',
            expertiseList: '',
            newComm: "",
            newBio: "",
            industryList: '',
            newBioKeyword: "",
            feedback: 0,
            availableFrequency: ['once a quarter', 'once a week', 'once a month', 'biweekly', 'daily', 'as often as needed'],
            member_status: '',
            memberFlag: '',
            frequency: '',
            capacity: '',
            propensity: '',
            requestStatus: '',
            responseStatus: '',
            groups: '',
            isEmailDisable: false,
            isEmailLinkDisable: false,
            accountOwnerList: '',
            loading: false,
        }
    },
    async created() {
        const dict = {
            custom: {
                linkedInURL: {
                    regex: "Please enter a valid URL"
                }
            }
        };
        this.loadData();
        this.getAllCommunity();
        this.$validator.localize('en', dict);
        const user = localStorage.getItem('authUser');
        if (user) {
            this.authUser = JSON.parse(user);
        }
    },
    computed: {
        remainingGroupCharacters() {
            return this.maxGroupCharacters - this.groups.length;
        },
        remainingRoleCharacters() {

            return this.maxRoleCharacters - this.member && this.member.roleDescription ? this.member.roleDescription.length: 0;
        },
        remainingOrganizationCharacters() {
            return this.maxOrganizationCharacters - this.member && this.member.organizationDescription ? this.member.organizationDescription.length: 0;
        }
    },
    methods: {
        loadData: async function() {
            try {
                this.loading = true;
                await this.axios.get(process.env.VUE_APP_API_BASE_URL + `/members/${this.$route.params.member_id}`).then(member => {
                    this.member = member.data;
                    this.member.advice_area = member.data.advice_area.toString();
                    this.member.roles = member.data.roles.toString();
                    this.member.member_rank = member.data.member_rank.toString();
                    this.member.affinity = member.data.affinity.toString();
                    this.member.phoneNumbers = member.data.phoneNumbers.toString().replace(/ /g, '');
                    this.industryList = member.data.industry.toString();
                    this.member.emails = member.data.emails.toString().replace(/ /g, '');
                    this.member.email_address2 = member.data.email_address2 && member.data.email_address2.toString().replace(/ /g, '');
                    this.member.email_address3 = member.data.email_address3 && member.data.email_address3.toString().replace(/ /g, '');
                    this.groups = member.data.groups.toString().replace(/,/g, ', ');
                    this.newBio = member.data.bio;
                    this.newComm = member.data.community;
                    var trimBioKeywords = member.data.bioKeywords.toString().trim();
                    this.newBioKeyword = trimBioKeywords
                                        .split(',')
                                        .map(keyword => keyword.trim())
                                        .filter(keyword => keyword.length > 0)
                                        .join(', ');
                    this.skillsList = member.data.skills.toString().replace(/,/g, ", ");
                    this.circleList = member.data.circles.toString().replace(/,/g, ", ");
                    this.accountOwnerList = member.data.account_owner.toString().replace(/,/g, ", ");
                    this.interestList = member.data.interests.toString().replace(/,/g, ", ");
                    if(this.member.unsubscribe) {
                        this.renderUnsubscribe = true;
                    }
                    if (member.data.memberType) {
                        this.memberType = member.data.memberType;
                    }
                    if (member.data.frequency) {
                        this.frequency = member.data.frequency;
                    }
                    if (member.data.capacity) {
                        this.capacity = member.data.capacity;
                    }
                    if (member.data.propensity) {
                        this.propensity = member.data.propensity;
                    }
                    if (member.data.requestStatus) {
                        this.requestStatus = member.data.requestStatus;
                    }
                    if (member.data.responseStatus) {
                        this.responseStatus = member.data.responseStatus;
                    }
                    if (member.data && member.data.emails && member.data.emails.length && member.data.email_address2 && member.data.email_address3) {
                        this.isEmailDisable = true;
                    }
                    this.member_status = member.data.member_status === true ? 'Active' : 'Inactive';
                    // if (member.data && member.data.roles && member.data.roles.length) {
                    //     this.member.roles = member.data.roles.join(',');
                    // }
                    if (member.data && member.data.emails && member.data.emails.length && member.data.email_address2 && member.data.email_address3) {
                        this.isEmailDisable = true;
                    }

                    if (this.member.memberType === "advisor") {
                        this.memberType = "Advisor";
                    } else if (this.member.memberType === "member") {
                        this.memberType = "Member";
                    }
                    if (this.member.memberFlag === "Unflagged") {
                        this.memberFlag = "Unflagged";
                    } else if (this.member.memberFlag === "Flagged") {
                        this.memberFlag = "Flagged";
                    }
                    if (!this.member.frequency) {
                        this.member.frequency = "once a week";
                    }
                });
                // console.log("this member : ",this.member)
                if(this.member && this.member.community) {
                    await this.axios.get(process.env.VUE_APP_API_BASE_URL + `/communities/id/${this.member.community}`).then(response => {
                        this.community = response.data;
                    });
                }
                this.loading = false;
            } catch (error) {
                console.log("error :", error);
                this.loading = false;
            }
        },
        validateState(ref) {
            if (this.errors.has(ref)) {
                return !this.errors.has(ref);
            } else {
                return null;
            }
        },
        getFeedback: async function () {
        await this.api.post(`members/feedback/${this.$route.params.member_id}`).then(memberFeedback => {
            this.feedback = memberFeedback.data;
        });
        },
        saveDetail: async function(scope) {
            try {

                if (typeof this.member._id !== 'undefined') {
                    this.$validator.validateAll(scope).then(async (result) => {
                        if (result) {
                            this.loading = true;
                            this.member.skills = this.skillsList;
                            this.member.industry = this.industryList;
                            this.member.circles = this.circleList;
                            this.member.account_owner = this.accountOwnerList;
                            this.member.interests = this.interestList;
                            if (this.newBio !== this.member.bio) {
                                await this.getKeywords(this.newBio);
                            }
                            this.member.groups = this.groups;
                            this.member.bio = this.newBio;
                            this.member.bioKeywords = this.newBioKeyword;
                            await this.axios.post(process.env.VUE_APP_API_BASE_URL + `/dashboard/member/update-profile/${this.$route.params.member_id}`, this.member,  {headers: {'x-access-token': this.token}}).then(member => {
                                this.loadData();
                                this.isEmailLinkDisable = false;
                                this.member = member.data.memberData;
                                if (member.data.isEmailExist) {
                                    this.$toast.error('Email address already exist');
                                }
                                if (this.newBio === this.member.bio) {
                                }
                            });
                        }
                    });
                }
            } catch (error) {
                console.log("error :", error);
                this.loading = false;
            }

        },
        saveDetail2: async function(field, value) {
            try {
                this.loading = true;
                var payload = {};
                if (field === 'memberType' && value !== this.member[field]) {
                    payload = {memberType: this.memberType, community: this.member.community};
                }
                if (field === 'memberFlag' && value !== this.member[field]) {
                    payload = {memberFlag: this.memberFlag, community: this.member.community};
                }
                if (field === 'frequency' && value !== this.member[field]) {
                    payload = {frequency: this.frequency};
                }
                if (field === 'requestStatus' && value !== this.member[field]) {
                    payload = {requestStatus: this.requestStatus};
                }
                if (field === 'responseStatus' && value !== this.member[field]) {
                    payload = {responseStatus: this.responseStatus};
                }
                if (field === 'member_status' && value !== this.member[field]) {
                    payload = {member_status: this.member_status};
                }
                // console.log(payload);
                var checkDataExists = Object.entries(payload);
                if (checkDataExists.length) {
                    await this.axios.post(process.env.VUE_APP_API_BASE_URL + `/dashboard/member/update-profile-fields/${this.$route.params.member_id}`, payload, {headers: {'x-access-token': this.token}}).then(member => {
                    this.loadData();
                    this.member = member.data;
                    });
                }
            } catch (error) {
                console.log("error :", error);
                this.loading = false;
            }

        },
        getKeywords: async function(Keywords) {
            try {
                this.loading = true;
                await this.axios.post(process.env.VUE_APP_API_BASE_URL + `/dashboard/members/update-bio-keyword/${this.$route.params.member_id}`, {bioKeywords: Keywords}, {headers: {'x-access-token': this.token}}).then(member => {
                    if (member.data.bioKeywords.length) {
                        let trimBioKeywords = member.data.bioKeywords.toString().replace(/ /g, '');
                        this.newBioKeyword = trimBioKeywords.replace(/,/g, ", ");
                    }
                });
            } catch (error) {
                console.log("error :", error);
                this.loading = false;
            }

        },
        getAllCommunity: async function () {
            try {
                this.loading = true;
                await this.axios.get(process.env.VUE_APP_API_BASE_URL +`/dashboard/community/all`, {headers: {'x-access-token': this.token}}).then(communities => {
                    this.allCommunities = communities.data;
                });
            } catch (error) {
                console.log("error :", error);
                this.loading = false;
            }

        },
        changeCommunity: async function () {
            try {
                this.loading = true;
                var selComm = this.newComm;
                var oldComm = "";
                if (typeof this.community._id !== 'undefined' || typeof this.member.community !== 'undefined') {
                    oldComm = this.community._id ? this.community._id : this.member.community ? this.member.community : '';
                }

                if (selComm !== oldComm) {
                    var parameters = {};
                    parameters.selComm = this.newComm;
                    parameters.oldComm = oldComm;
                    await this.axios.post(process.env.VUE_APP_API_BASE_URL + `/dashboard/members/update-user-community/${this.$route.params.member_id}`, parameters, {headers: {'x-access-token': this.token}}).then(member => {
                    this.loadData();
                    this.member = member.data;
                    });
                }
            } catch (error) {
                console.log("error :", error);
                this.loading = false;
            }

        },
        deleteMember: async function () {
            await this.axios.post(process.env.VUE_APP_API_BASE_URL +  `/dashboard/members/delete/${this.$route.params.member_id}`, "", {headers: {'x-access-token': this.token}}).then(member => {
                this.member = member.data;
                });
        },
        toTitleCase(str) {
            return str.replace(
                /\w\S*/g,
                function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
            );
        },
        unsubscribeMember: async function () {
            if (this.member.community) {
                const unsubscribe = {
                    unsubscribe:
                    {
                        email: this.member.emails.toString(),
                        reason: '',
                        description: ''
                    },
                    community: this.member.community,
                    isMailSent: false,
                    memberId: this.member._id
                }
                await this.axios.post(process.env.VUE_APP_API_BASE_URL + '/members/unsubscribe', unsubscribe).then(response => {
                    this.loadData();
                });
            } else {
                this.$toast.error('Please select community.');
            }
        }
    }
}
</script>
