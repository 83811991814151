<template>
    <div class="container-bak">
      <div class="row-bak d-flex-bak align-items-center-bak header_table align-center">
        <label class="col-4-bak" for="datetime"
        style="margin: 0px 10px 0px 0px">{{label}}{{ label != ""? ":": ""}}</label>
        <input name="datetime" type="hidden" v-model="selectedDates">
        <b-form-select
            v-model="selectedPreset"
            class="col-6-bak filter"
            style="width: auto;" 
        >
            <option :value="null" selected :disabled="selectedValue == null">All</option>
            <option value="last-7" :disabled="selectedValue == 'last-7'">Last 7 Days</option>
            <option value="last-30" :disabled="selectedValue == 'last-30'">Last 30 Days</option>
            <option value="ytd" :disabled="selectedValue == 'ytd'">Year to Date</option>
            <option value="custom" :disabled="selectedValue == 'custom'" v-if="this.isCustomDateSelected">
              {{String(range.start.getMonth() + 1).padStart(2, '0')}}/{{String(range.start.getDate()).padStart(2, '0')}}/{{range.start.getFullYear() }}-{{String(range.end.getMonth() + 1).padStart(2, '0')}}/{{String(range.end.getDate()).padStart(2, '0')}}/{{range.end.getFullYear() }}
            </option>
            <option value="custom" :disabled="selectedValue == 'custom'" v-else>Custom dates</option>
          </b-form-select>
        <div v-if="selectedPreset == 'custom'">
        <b-modal id="date-modal" class="date-modal" size="sm" v-model="dateModal" ok-title="Submit" @ok="isCustomDateSelected = true" title="Date Range Filter">
          <v-date-picker class="col-8-bak datepicker-wrap" 
            style="width: 100%;"
            v-model="range"
            is-range
            :is-dark="true">
          </v-date-picker>
        </b-modal>
        
        </div>
      </div>
    </div>
  </template>
  <style>
  @import "~@coreui/coreui-pro/dist/css/coreui.min.css";
  .datepicker-wrap {
    padding: 0px !important;
  }
  input {
    width: 100%;
  }
  </style>
  <script>
  import Vue from 'vue';
  import VCalendar from 'v-calendar'
  Vue.use(VCalendar);

  export default {
    name: 'datepicker',
    data() {
      return {
        isCustomDateSelected: false,
        selectedDates: {
          startDate: null,
          endDate: null
        },
        isDateSelected: false,
        selectedPreset: null,
        range: {
          start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          end: new Date(new Date().getFullYear(), new Date().getMonth(), 5)
        },
        dateModal: false,
        selectedValue: null,
      }
    },
    props: ['label','value'],
    methods: {
    },
    watch: {
       value: {
        immediate: true,
        handler(newValue) {
          this.selectedPreset = newValue || null;
        }
      },
      isCustomDateSelected: function() {
        if(this.isCustomDateSelected) {
          this.selectedDates.startDate = this.range.start;
          this.selectedDates.endDate = this.range.end;
          this.isDateSelected  = true
        }
      },
      isDateSelected: function(value) {
        console.log("value: ", value)
        if(value == true || value == null) {
          this.$emit('changed', this.selectedDates);
          this.isDateSelected = false;
        }
      },
      selectedPreset: function(newVal) {
        this.selectedValue = newVal;
        this.isCustomDateSelected = false;
        const currentDate = new Date();
        // Extract the year from the current date
        const currentYear = currentDate.getFullYear();
        let formattedDate;
        if (newVal == 'last-7' || newVal == 'last-30') {
          // Define the number of days to count back
          let daysToSubtract;
          switch(newVal) {
            case 'last-7':
              daysToSubtract = 7;
              break;
            case 'last-30':
              daysToSubtract = 30;
              break;
          }
          // Calculate the new date by subtracting days
          const newDate = new Date(currentDate);
          newDate.setDate(currentDate.getDate() - daysToSubtract);
          // Format the new date to MM/DD/YYYY
          const day = String(newDate.getDate()).padStart(2, '0');
          const month = String(newDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1
          const year = newDate.getFullYear();
          formattedDate = `${month}/${day}/${year}`;
        } else if (newVal == 'ytd') {
          formattedDate = `01/01/${currentYear}`;
        } else if (newVal == 'custom') {
          this.dateModal = true;
        } else if (newVal == null) {
          console.log("newVal: ", newVal)
          this.selectedDates.startDate = new Date(0);
          this.selectedDates.endDate = new Date();
          this.isDateSelected  = true
        }
        if (formattedDate) {
          console.log("newVal: ", newVal)
          this.selectedDates.startDate = new Date(formattedDate);
          this.selectedDates.endDate = new Date();
          this.isDateSelected  = true
        }
      }
    }
  };
  </script>