<template>
    <div>
        <b-card>
            <template slot="header">
                <h4 class="mt-2 custom-card-header-text">Members</h4>
                <ul class="list-inline float-right">
                    <!-- <li class="list-inline-item">
                        <b-form-select v-model="selected" v-on:change="filterByCommunity" class="custom-card-header-filter">
                            <option :value="null" selected disabled>Community</option>
                            <option value="all">All</option>
                            <option v-for="community in communities" v-bind:value="community._id">{{community.name}}</option>
                        </b-form-select>
                    </li> -->
                    <li class="list-inline-item">
                        <button type="button" aria-label="Export" aria-live="assertive" @click="exportData()" v-b-tooltip.hover.bottom title="Download all member data" class="btn custom-card-header-filter btn-dropbox tab-focus"><i class="icon-cloud-download"></i></button>
                    </li>
                </ul>
            </template>
            <b-card-body>
                <v-server-table ref="membersTable" :url="serverURL" :columns="columns" :options="options" name="dataTable" id="dataTable" @loaded="onLoaded" class="members-table">
                    <span slot="community" slot-scope="props">
                        {{props.row.community ? props.row.community : 'N/A'}}
                    </span>
                    <span slot="name" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if="props.row.memberId">
                        {{props.row.firstname}} {{props.row.lastname}}
                    </span>
                    <span slot="name" slot-scope="props" v-else>
                        {{props.row.firstname}} {{props.row.lastname}}
                    </span>
                    <span slot="email" slot-scope="props" class="display-link" @click="redirectUrls(props.row.memberId)" v-if="props.row.memberId">
                        {{props.row.email}}
                    </span>
                    <span slot="email" slot-scope="props" v-else-if = "props.row.email">
                        {{props.row.email}}
                    </span>
                    <span slot="action" slot-scope="props" class="res-req-action-width">
                        <ul class="list-inline mb-0 text-center">
                            <li class="list-inline-item">
                                <b-button v-b-tooltip.hover.bottom title="View the member" variant="dropbox tab-focus" size="sm" @click="$router.push(`/engagement/members/${props.row.memberId}`)"><i class="icon-eye"></i></b-button>
                            </li>
                            <li class="list-inline-item" v-if="props.row.status === 'Active' && props.row.email">
                                <b-dropdown class="float-right" v-b-tooltip.hover.bottom title="Take action" variant="btn btn-dropbox btn-sm tab-focus" right>
                                    <template slot="button-content">
                                      <i class="icon-settings"></i>
                                    </template>
                                    <b-dropdown-item @click="unsubscribeMember(props.row.memberId, props.row.email, props.row.communityId)">Unsubscribe Member</b-dropdown-item>
                                    <b-dropdown-item @click="openEmailModal(props.row.email, props.row.memberId, props.row.communityId)" v-if="props.row.memberId && props.row.communityId && !props.row.email_address3">Edit Email</b-dropdown-item>
                                </b-dropdown>
                            </li>
                        </ul>
                    </span>
                </v-server-table>
            </b-card-body>
            <b-modal id="email-modal" class="email-modal" size="md" v-model="emailModal" ok-title="Close" @ok="emailModal = false" title="Update Email">
                <b-card-body>
                    <b-form v-on:submit.prevent="updateEmailSubmit">
                        <b-form-group
                        label="Email:"
                        label-for="Email"
                        >
                            <b-form-input
                            type="text"
                            name="email"
                            v-model="updateEmail"
                            v-validate="{ required: true, max:100, email: true }"
                            :state="validateState('email')"
                            placeholder="Enter email"
                            >
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors.has('email')">
                            {{errors.first('email')}}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-button type="submit" variant="primary">Update</b-button>
                    </b-form>
                </b-card-body>
            </b-modal>
        </b-card>
    </div>
</template>

<script>
import { shuffleArray } from '@/shared/utils'
import axios from 'axios'
import { exportCSV } from '@/assets/js/common';
var moment = require('moment-timezone');
import VueNotifications from 'vue-notifications'

export default {
    name: 'members',
    data() {
        return {
            members: [],
            communities: [],
            selected: null,
            perPage: 25,
            token: localStorage.getItem('token'),
            columns: ['community', 'name', 'email', 'engagementPoints','action'],
            options: {
                headings: {
                    engagementPoints: 'Engagement Score'
                },
                sortable: ['community', 'name', 'email', 'engagementPoints'],
                sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
                pagination: {
                    chunk: 10,
                    nav: 'fixed'
                },
                requestFunction: async function (data) {
                    let queryData = {
                        ...this.$route.query,
                        ...data
                    };
                    return await axios.get(this.url, {headers: {'x-access-token': localStorage.getItem('token')},  params: queryData}).catch(function (e) {
                        if (e.response.status  === 401) {
                            localStorage.removeItem('authUser');
                            this.$router.push('/login');
                        }
                    }.bind(this));
                },
                responseAdapter({data}) {
                    localStorage.setItem('member_count', data.memberCount);
                    return {
                        data : data.membersData,
                        count: data.memberCount
                    }
                },
                filterable: true,
            },
            memberUri: `${process.env.VUE_APP_API_BASE_URL}/dashboard/members/count`,
            membersCount: 0,
            userData: {},
            serverURL: `${process.env.VUE_APP_API_BASE_URL}/dashboard/members/advanced-search`,
            emailModal: false,
            updateEmail: '',
            memberEmailOfId: '',
            communityId: ''
        }
    },
    watch: {
        '$route': function(){
            this.loadData();
        }
    },
    notifications: {
        showSuccessMsg: {
            type: VueNotifications.types.success,
            message: 'We are now preparing your export and will send you an email once it is available for download.',
            timeout: 3000
        },
        showErrorMsg: {
            type: VueNotifications.types.error
        },
        showWarnMsg: {
            type: VueNotifications.types.warn,
            message: "No data found for export file"
        }
    },
    async created() {
        this.token = localStorage.getItem('token');
        const dict = {
            custom: {
                email: {
                    required: "Please enter email.",
                    max: "Email cannot be more than 100 characters.",
                    email: 'Please enter valid email address.'
                }
            }
        };
        this.$validator.localize('en', dict);
    },
    methods: {
        onLoaded() {
            this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/members/advanced-search`;
        },
        loadData() {
            let qs = Object.keys(this.$route.query).map(key => `${key}=${this.$route.query[key]}`).join('&');
            this.serverURL = `${process.env.VUE_APP_API_BASE_URL}/dashboard/members/advanced-search/`;
        },
        exportData: function() {
            let memberCount = localStorage.getItem('member_count');
            let qs = Object.keys(this.$route.query).map(key => `${key}=${this.$route.query[key]}`).join('&');
            if (memberCount && parseInt(memberCount) < 1200) {
                this.axios.get(`${this.serverURL}?${qs}&export=csv`, {headers: {'x-access-token': this.token}}).then(async response => {
                    if (response.data.memberCount > 0) {
                        let member = exportCSV(response.data.membersData.slice(), 'members', ['requestId', 'memberId', 'communityId'], ['last_request_made', 'last_response_sent', 'last_request_received', 'last_response_received', 'terms_accepted']);
                    } else {
                        this.showWarnMsg();
                    }
                }).catch(err => {
                    if (err.response.status  === 401) {
                        localStorage.removeItem('authUser');
                        this.$router.push('/login');
                    }
                });
            } else {
                this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/members/exportCSV?${qs}&export=csv`, {headers: {'x-access-token': this.token}}).then(response => {
                    this.showSuccessMsg();
                }).catch(err => {
                    if (err.response.status  === 401) {
                        localStorage.removeItem('authUser');
                        this.$router.push('/login');
                    }
                });
            }
        },
        redirectUrls: function(path) {
            window.open((`/engagement/members/${path}`), '_blank');
        },
        unsubscribeMember: async function(memberId, email, communityId) {
            if (communityId) {
                const unsubscribe = {
                    unsubscribe:
                    {
                        email: email.toString(),
                        reason: '',
                        description: ''
                    },
                    community: communityId,
                    isMailSent: false,
                    memberId: memberId
                }
                await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/members/unsubscribe`, unsubscribe).then(async response => {
                    this.$refs.membersTable.refresh();
                }).catch(err => {
                    if (err.response.status  === 401) {
                        localStorage.removeItem('authUser');
                        this.$router.push('/login');
                    }
                });
            } else {
                this.showErrorMsg({message: 'Community not assign'});
            }
        },
        openEmailModal: function(email, memberId, communityId) {
            this.emailModal = true;
            this.updateEmail = '';
            this.memberEmailOfId = memberId;
            this.communityId = communityId;
        },
        validateState(ref) {
            if (this.errors.has(ref)) {
                return !this.errors.has(ref);
            } else {
                return null;
            }
        },
        updateEmailSubmit() {
            this.$validator.validateAll().then(async result => {
                if (result) {
                    let memberObj = {
                        email: this.updateEmail,
                        memberId: this.memberEmailOfId,
                        communityId: this.communityId
                    }
                    await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/members/update-email`, memberObj, {headers: {'x-access-token': this.token}}).then(async response => {
                        this.emailModal = false;
                        this.$refs.membersTable.refresh();
                    }).catch(err => {
                        if (err.response.status  === 401) {
                            localStorage.removeItem('authUser');
                            this.$router.push('/login');
                        }
                    });
                }
            })
        }
    }
}
</script>
